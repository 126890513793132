import classNames from 'classnames';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { constants } from '../../../../constants/constants';
import IconSVG from '../../../../styles/svg-icons';
import { EmptyPlaceholder } from '../../../common';
import { ChipList } from '../../../filters/ChipList';

interface Props {
    placeholder?: string;
    onSearch: (searchItems: string[]) => any;
    selectedSearchItems: string[],
    className?: string;
    data: string[];
    disabled?: boolean;
}

export const ArrangerPipelineSearchDeals = ({
    placeholder = '',
    onSearch,
    className,
    data,
    selectedSearchItems,
    disabled = false,
}: Props) => {
    const refInput = useRef<HTMLInputElement>(null);
    const refScrollableContainer = useRef<HTMLDivElement>(null);
    const refActiveItem = useRef<HTMLTableRowElement | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTermItems, setSearchTermItems] = useState<string[]>(selectedSearchItems);
    const [markerPosition, setMarkerPosition] = useState(-1);
    const [filteredItems, setFilteredItems] = useState<string[]>([]);
    const [index, setIndex] = useState(0);
    const [focusInput, setFocusInput] = useState(false);

    const getFilteredItems = useCallback(
        (value: string) => {
            const valueLowerCase = value.trim().toLowerCase();

            return data.filter(i => i.toLowerCase().includes(valueLowerCase));
        },
        [data],
    );

    useEffect(() => {
        if (refScrollableContainer.current && refActiveItem.current && data.length) {
            const container = refScrollableContainer.current.getBoundingClientRect();
            const item = refActiveItem.current.getBoundingClientRect();
            if (item.top < container.top || item.bottom > container.bottom) {
                refActiveItem.current.scrollIntoView(false);
            }
        }
    }, [data, index]);


    useEffect(() => {
        if (selectedSearchItems.length && !searchTermItems.length) {
            setSearchTermItems(selectedSearchItems);
        }

        if (!selectedSearchItems.length) {
            setSearchTermItems([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(selectedSearchItems)])

    useEffect(() => {
        setFilteredItems(getFilteredItems(searchTerm));
    }, [searchTerm, getFilteredItems]);

    useEffect(() => {
        onSearch(searchTermItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTermItems]);

    const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        setIndex(0);
    };

    const handleClearAll = () => {
        setIndex(0);
        refInput.current && refInput.current.focus();
        setSearchTerm('');
        setSearchTermItems([]);
        setMarkerPosition(-1);
    };

    const handleLookupItemClick = (lookupItem: string) => {
        setIndex(0);

        refInput.current && refInput.current.focus();

        if (!searchTermItems.find(s => s === lookupItem)) {
            setSearchTermItems([...searchTermItems, lookupItem]);
        }

        setSearchTerm('');
    };

    const searchMoveBack = () => {
        if (markerPosition > 0) {
            setMarkerPosition(markerPosition - 1);
        }

        if (markerPosition === -1) {
            setMarkerPosition(searchTermItems.length - 1);
        }
    };

    const searchMoveForward = () => {
        if (markerPosition >= 0 && markerPosition < searchTermItems.length - 1) {
            setMarkerPosition(markerPosition + 1);
        }

        setMarkerPosition(-1);
    };

    function getMarkerPositionAfterItemRemove(markerPosition: number, removedIndex: number) {
        return markerPosition > removedIndex ? markerPosition - 1 : markerPosition;
    }

    const handleRemoveChip = (i: number) => {
        setSearchTermItems(searchTermItems.filter((item, index) => index !== i));
        setMarkerPosition(getMarkerPositionAfterItemRemove(markerPosition, i));

        if (refInput.current) {
            refInput.current.focus();
        }
    };

    const removeCurrentItem = () => {
        if (markerPosition > -1 && markerPosition < searchTermItems.length) {
            setSearchTermItems(searchTermItems.filter((item, index) => index !== markerPosition));
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === 8) {
            if (markerPosition > 0 && !searchTerm) {
                removeCurrentItem();
            } else if (e.currentTarget.selectionStart === 0 && !searchTerm) {
                handleRemoveChip(searchTermItems.length - 1);
            }
        } else if (!searchTerm && e.keyCode === 37 && e.currentTarget.selectionStart === 0) {
            searchMoveBack();
        } else if (!searchTerm && e.keyCode === 39 && markerPosition !== -1) {
            e.preventDefault();
            searchMoveForward();
        }
        if (filteredItems.length) {
            if (e.keyCode === 13) {
                // enter
                handleLookupItemClick(filteredItems[index]);
            } else if (e.keyCode === 38) {
                // move up
                e.preventDefault();
                if (index > 0) {
                    setIndex(index - 1);
                }
            } else if (e.keyCode === 40 && index < filteredItems.length - 1) {
                // move down
                setIndex(index + 1);
            }
        }
    };

    const handleFocus = () => {
        setFocusInput(true);
    };

    const handleBlur = () => {
        setFocusInput(false);
    };

    const renderLookupItem = (item: string, i: number) => {
        return (
            <tr
                key={i}
                ref={node => (refActiveItem.current = index === i ? node : refActiveItem.current)}
                className={classNames({ active: index === i })}
                onClick={() => handleLookupItemClick(item)}
            >
                <td className="col-main">
                    <div className={classNames('result-row-wrap')}>
                        <span className="result-row-name">{item}</span>
                    </div>
                </td>
            </tr>
        );
    };

    return (
        <div
            className={classNames('control-search-wrapper control-search-wrapper-arranger-pipeline', className, {
                'control-search-wrapper-focused': focusInput, disabled
            })}
        >
            <div className="form-control-search-btn flex-none">
                <i className="icon icon-search" />
            </div>
            <ChipList list={searchTermItems} selectionIndex={markerPosition} onRemove={handleRemoveChip} />
            <input
                ref={refInput}
                className="form-control form-control-search"
                type="text"
                value={searchTerm}
                disabled={disabled}
                onChange={handleSearchTermChange}
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder={searchTermItems.length ? '' : placeholder}
            />
            {(!!searchTerm || !!searchTermItems.length) && (
                <span onClick={handleClearAll} className="btn-close">
                    <IconSVG name="close" width="16" height="16" />
                </span>
            )}
            {searchTerm.length > constants.searchTermAcceptedLength && (
                <div ref={refScrollableContainer} className="search-lookup">
                    <table>
                        {filteredItems.length ? (
                            <tbody>{filteredItems.map((item, index) => renderLookupItem(item, index))}</tbody>
                        ) : (
                            <EmptyPlaceholder textView />
                        )}
                    </table>
                </div>
            )}
        </div>
    );
};
